<template>
  <header class="navbar is-link">
    <nav class="navbar is-link container" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img src="../assets/logo.png" width="113" height="80" />
        </router-link>

        <!-- Кнопка для мобильного меню -->
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': isMenuActive }"
          aria-label="menu"
          aria-expanded="false"
          @click="toggleMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <!-- Меню -->
      <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isMenuActive }">
        <div class="navbar-end is-link">

          <!--Пункты меню -->
          <router-link to="/" class="navbar-item" v-if="$route.path !== '/'">Поиск</router-link>
          <!-- Условие для скрытия пункта меню "Оставить заявку" на главной странице -->
          <a v-if="$route.path !== '/'" class="navbar-item" @click="openModal">Оставить заявку</a>
          <router-link to="/about" class="navbar-item">О нас</router-link>
          <router-link to="/contact" class="navbar-item">Контакты</router-link>
          <!-- <router-link to="/top-apartments" class="navbar-item">Лучшие предложения</router-link> -->
           <!-- Выпадающий список -->
          <!-- <div class="navbar-item has-dropdown is-hoverable">
            <router-link to="/about" class="navbar-link">О компании</router-link>
            <div class="navbar-dropdown">
              <router-link to="/about" class="navbar-item">О нас</router-link>
              <router-link to="/vacancies" class="navbar-item">Вакансии</router-link>
              <router-link to="/contact" class="navbar-item">Контакты</router-link>
            </div>
          </div> -->

        </div>
      </div>
    </nav>
    <Modal ref="modal" />
  </header>
</template>

<script>
import Modal from './Modal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isMenuActive: false, // Управляет состоянием мобильного меню
    };
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive; // Переключение состояния меню
    },
  },
};
</script>

<style scoped>
/* При необходимости можете добавить стили для мобильной версии */
</style>