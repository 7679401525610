<template>
<section class="modal has-background-link" v-if="isVisible">
  <div class="is-fullwidth is-flex is-align-self-flex-end">
    <button class="delete is-large m-3" aria-label="delete" @click="closeModal"></button>
  </div>
  <div class="container is-flex is-justify-content-center p-4">
    <p class="buttons">
            <button class="button">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'phone']" />
              </span>
              <span><a href="tel:+79854102011">Телефон</a></span>
            </button>
  
            <button class="button">
              <span class="icon">
                <font-awesome-icon :icon="['fab', 'whatsapp']" />
              </span>
              <span><a href="https://wa.me/79854102011?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82...">Whatsapp</a></span>
            </button>
  
            <button class="button">
              <span class="icon">
                <font-awesome-icon :icon="['fab', 'telegram']" />
              </span>
              <span><a href="https://t.me/+79854102011">Telegram</a></span>
            </button>
    </p>
  </div>
</section>
</template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
      };
    },
    methods: {
      openModal() {
        this.isVisible = true;
      },
      closeModal() {
        this.isVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    display: flex;
    align-items: left;
    justify-content: left;
  }
  .modal-background {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .modal-content {
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
  } 
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  </style>
  