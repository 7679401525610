import { createApp } from 'vue';
import App from './App.vue';

import router from './router.js';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faPhone, faWhatsapp, faTelegram);
import './assets/styles/main.scss';

const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.mount('#app');
