<template>

  <!-- <section class="hero is-medium" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')', backgroundSize: 'cover', backgroundPosition: 'center' }">
  <div class="hero-body">
    <div class="">
    <p class="title">ЮКА - агрегатор новостроек Москвы и Московской области.</p>
    <p class="subtitle">500+ новостроек Москвы и Московской области от лучших застройщиков без комиссии и переплат</p>
  </div>
    </div>
</section> -->

  <section class="container px-2 pt-4">
    <div class="columns py-2">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h1 class="title is-size-4">Новостройки</h1>
              <p class="subtitle is-size-6">Поможем бесплатно подобрать новостройку под ваши критерии</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h1 class="title is-size-4">Срочная продажа</h1>
              <p class="subtitle is-size-6">Найдем заинтересованных покупателей в кратчайшие сроки</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h1 class="title is-size-4">Без коммиссии!</h1>
              <p class="subtitle is-size-6">Продать квартиру или компнату в Москве и Московской области</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h1 class="title is-size-4">Можно уже заехать</h1>
              <p class="subtitle is-size-6">Готовые квартиры в новостройках в Москве и Московской области.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <nav class="level has-background-black-ter has-text-white p-5">
    <div class="level-item has-text-centered">
      <div>
        <p class="title has-text-white  pb-2">500+</p>
        <p class="heading">Жилых комплексов в Москве и Московской области .</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="title has-text-white  pb-2">5%*</p>
        <p class="heading">Самая низкая ставка по семейной ипотеке
          (МКБ, лицензия ЦБ РФ № 1978 от 06.05.2016)</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="title has-text-white  pb-2">0 руб</p>
        <p class="heading">Наши клиенты не платят комиссию, потому что мы работаем напрямую от застройщиков</p>

      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      backgroundImageUrl: require('@/assets/hero.webp') // Замените путь на соответствующий
    };
  }
};
</script>

<style scoped>
.heading {
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
  max-width: 200px;
}
</style>