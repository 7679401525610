<template>
    <section class="container">
        <div class="title is-5">Партнеры</div>
        <div class="columns is-multiline">
            <div class="column is-2"><img class="img_partners" src="../assets/companies/1.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/2.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/3.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/4.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/5.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/6.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/7.png"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/8.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/9.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/10.jpg"></div>
        </div>
    </section>
</template>

<style scoped>
.img_partners {
  max-width: 150px;
  max-height: 100px;
}

.partners-wrapper {
  overflow-x: hidden; /* По умолчанию скрываем прокрутку */
}

@media (max-width: 768px) {
  .partners-wrapper {
    overflow-x: auto; /* Включаем горизонтальную прокрутку на мобильных устройствах */
    white-space: nowrap; /* Устраиваем элементы в одну строку */
  }

  .columns {
    display: flex; /* Используем flexbox для горизонтального выравнивания */
    flex-wrap: nowrap; /* Отключаем перенос строк */
    gap: 10px; /* Задаём расстояние между колонками */
  }

  .column {
    flex: 0 0 auto; /* Фиксируем ширину колонок */
  }
}
</style>