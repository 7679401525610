<script setup>
import SiteHeader from './SiteHeader.vue'
import FooterPromo from './FooterPromo.vue'

</script>

<template>
    <SiteHeader />

    <div class="container">

        <div v-if="loading">Loading...</div>
        <div v-if="error">{{ error.message }}</div>

        <div v-if="block" class="slider px-2">
            <!-- Use v-slot directive for named slots -->
            <!-- <swiper :autoHeight="true" :navigation="true" :spaceBetween="30" :pagination="{
                clickable: true,
            }" :modules="modules" :options="swiperOptions" class="mySwiper">
                <template v-for="(image, index) in block.renderer" :key="index">
                    <swiper-slide>
                        <img :src="image" sizes="(max-width: 600px) 480px, (max-width: 992px) 768px, 1200px"
                            alt="Image">
                    </swiper-slide>
                </template>
<template v-slot:pagination>
                    <swiper-pagination></swiper-pagination>
                </template>
</swiper> -->
            <swiper :navigation="true" :spaceBetween="30" class="mySwiper">
                <swiper-slide v-for="(image, index) in block?.renderer" :key="index">
                    <img :src="image" sizes="(max-width: 600px) 480px, (max-width: 992px) 768px, 1200px"
                        alt="Slide image" />
                </swiper-slide>
            </swiper>
        </div>


        <div class="columns">
            <div class="column is-4">
                <div class="images_block" v-if="block">

                    <!-- <div class="plans">
                        <div class="plan_block">
                            <div v-if="block.plan && block.plan.length > 0">
                                <div v-for="(image, index) in block.plan" :key="index">
                                    <img :src="image" sizes="(max-width: 600px) 480px, (max-width: 992px) 768px, 1200px"
                                        alt="Apartment Plan" style="max-width: 100%;" />
                                </div>
                            </div>
                        </div> -->

                    <div v-if="apartment">
                        <!-- <div><strong>block_id:</strong> {{ apartment.block_id }}</div> -->
                        <!-- <div><strong>block_name:</strong>{{ apartment.block_name }}</div> -->
                        <div><strong>Цена: </strong>{{ apartment.price }}</div>

                        <div><strong>Застройщик: </strong>{{ apartment.block_builder_name }}</div>
                        <div><strong>Дата сдачи проекта: </strong>{{ apartment.building_deadline }}</div>

                        <div><strong>ЖК: </strong>{{ apartment.block_name }}</div>
                        <div><strong>Адрес: </strong>{{ apartment.block_address }}</div>
                        <div><strong>Номер кв: </strong>{{ apartment.number }}</div>

                        <div><strong>Этаж: </strong>{{ apartment.floor }}</div>
                        <div><strong>Комнат: </strong>{{ apartment.room }}</div>
                        <div><strong>Кол-во туалетов: </strong>{{ apartment.wc_count }}</div>

                        <div><strong>Площадь: </strong>{{ apartment.area_given }} м2</div>
                        <div><strong>Кухня: </strong>{{ apartment.area_kitchen }} м2</div>
                        <div><strong>Комнаты: </strong>{{ apartment.rooms_total }} м2</div>
                        <div><strong>Балкон: </strong>{{ apartment.balconies_total }} м2</div>
                        <div><strong>Жилая: </strong>{{ apartment.area_total }} м2</div>
                        <div><strong>Высота потолков: </strong>{{ apartment.height }}</div>
                    </div>

                    <div class="plan_apart is-flex is-justify-content-center">
                        <!-- Display images from apartment.plan -->
                        <div v-if="apartment && apartment.plan && apartment.plan.length > 0">
                            <div v-for="(image, index) in apartment.plan" :key="index">
                                <img :src="image" sizes="(max-width: 600px) 480px, (max-width: 992px) 768px, 1200px"
                                    alt="Apartment Plan" style="max-width: 100%; max-height: 300px;" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="column is-8">
                <div class="text" v-if="block">
                    <!-- <h2 class="title is-5">Apartment Details - {{ id }}</h2> -->
                    <div v-if="block">
                        <!-- <div><strong>Имя:</strong>{{ block.name }}</div> -->
                        <div v-html="block.description"></div>
                        <!-- Display an image from block.plan -->

                    </div>
                </div>
                <br>

                <div class="card mx-2">
                    <div class="card-content">
                        <p class="title">
                            “Остались вопросы?”
                        </p>
                        <p class="subtitle">Мы с удовольствием Вам все расскажем и поможем подобрать лучший вариант</p>
                    </div>
                    <footer class="card-footer">
                        <p class="card-footer-item is-flex is-justify-content-left">
                        <p class="buttons">
                            <button class="button">
                                <span class="icon">
                                    <font-awesome-icon :icon="['fas', 'phone']" />
                                </span>
                                <span>Телефон</span>
                            </button>

                            <button class="button">
                                <span class="icon">
                                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                                </span>
                                <span>Whatsapp</span>
                            </button>

                            <button class="button">
                                <span class="icon">
                                    <font-awesome-icon :icon="['fab', 'telegram']" />
                                </span>
                                <span>Telegram</span>
                            </button>
                        </p>
                        </p>
                    </footer>
                </div>

            </div>

        </div>

    </div>
    <!-- <button class="button is-primary" @click="openModal">Открыть модальное окно</button> -->
    <FooterPromo />

</template>

<script>

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            loading: false,
            error: null,
            id: this.$route.params.id,
            apartment: null,
            block: null
        };
    },
    mounted() {
        this.fetchApartmentDetails();
    },
    methods: {
        async fetchApartmentDetails() {
            this.loading = true;
            this.error = null;

            try {
                // Fetch apartment details using the ID from the route params
                const response = await this.fetchGraphQL(`
            query ApartmentDetails($id: String!) {
              apartment(id: $id) {
                _id
                area_balconies_total
                area_given
                area_kitchen
                area_rooms
                area_rooms_total
                block_address
                block_name
                block_builder_name
                block_district_name
                block_id
                block_subway_name
                block_geometry {
                    coordinates
                    type
                }
               block_subway {
                    distance_time
                    distance_type
                    subway {
                        name
                    }
                }
                building_deadline
                floor
                floors
                height
                number
                plan
                price
                room
                wc_count
                }
              }
          `, { id: this.$route.params.id });

                this.apartment = response.apartment;

                // После получения данных о квартире, вызовем метод для получения данных о блоке
                this.fetchBlockDetails(this.apartment.block_id);
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        async fetchBlockDetails(blockId) {
            try {
                const blockResponse = await this.fetchGraphQL(`
          query BlockDetails($blockId: String!) {
            block(id: $blockId) {
              _id
              name
              address
              description
              renderer
              plan
            }
          }
        `, { blockId });

                this.block = blockResponse.block;
            } catch (error) {
                console.error('Error fetching block details:', error);
            }
        },
        async fetchGraphQL(query, variables = {}) {
            const response = await fetch('https://yuka.moscow:4000/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    variables,
                }),
            });

            const result = await response.json();
            return result.data || {};
        },
    },
};

</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.slider {
    width: 100%;
    padding: 0.5em 0 0.3em 0;
}

.mySwiper {
    /* height: 400px; */
    border-radius: 40px;

}

.images_block {
    width: 100%;
    padding: 20px;
}


.text {
    width: 100%;
    padding: 20px;
}

.plans {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan_block {
    width: 50%;
}

.plan_apart {
    width: 100%;
    padding-top: 42px;
}
</style>